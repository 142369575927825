<template>
  <div class="Help">
    <div class="header">
      <div class="header-text">
        <h2>用户中心</h2>
      </div>
    </div>
    <div class="Help-box">
      <div class="Help-content">
        <router-link to="/Home/MyMessage/announcement"/>
         <router-view/>
      </div>
    </div>
  </div>
</template>

<script>
import unifildStyle from "../../assets/css/help/unifildStyle.less";

import InforMation from '../messageComponents/inforMation';
import AnnounCementfrom from '../messageComponents/announcement'
export default {
  components: {
    InforMation,
    AnnounCementfrom
  },
  data() {
    return {
      ShowHelpContentObj: true,
      indexs: -1,
      list: [
        {
          index: 1,
          titel: "公告1",
          icon: "&#xe62d;",
          content:
            `
            <div class="content-text">
              <ul>
                <p>极少用户使用微信登录MindMaster软件后，发现云文件丢失，并且个人ID发生改变。</p>
                <p>遇到该问题别担心，复制以下链接至电脑浏览器https://account.edrawsoft.cn/mergeData.html</p>
                <p>打开后，使用微信重新扫码登录即可恢复正常啦！</p>
                <p>若依旧无法恢复，可微信后台留言求助亿图客服。</p>
              </ul>
            </div>
            `,
        },
        {
          index: 2,
          titel: "公告1",
          icon: "&#xe62d;",
          content:
             `
            <div class="content-text">
              <ul>
                <p>极少用户使用微信登录MindMaster软件后，发现云文件丢失，并且个人ID发生改变。</p>
                <p>遇到该问题别担心，复制以下链接至电脑浏览器https://account.edrawsoft.cn/mergeData.html</p>
                <p>打开后，使用微信重新扫码登录即可恢复正常啦！</p>
                <p>若依旧无法恢复，可微信后台留言求助亿图客服。</p>
              </ul>
            </div>
            `,
        },
        {
          index: 3,
          titel: "公告1",
          icon: "&#xe62d;",
          content:
            `
            <div class="content-text">
              <ul>
                <p>极少用户使用微信登录MindMaster软件后，发现云文件丢失，并且个人ID发生改变。</p>
                <p>遇到该问题别担心，复制以下链接至电脑浏览器https://account.edrawsoft.cn/mergeData.html</p>
                <p>打开后，使用微信重新扫码登录即可恢复正常啦！</p>
                <p>若依旧无法恢复，可微信后台留言求助亿图客服。</p>
              </ul>
            </div>
            `,
        },
        {
          index: 4,
          titel: "公告1",
          icon: "&#xe62d;",
          content:
             `
            <div class="content-text">
              <ul>
                <p>极少用户使用微信登录MindMaster软件后，发现云文件丢失，并且个人ID发生改变。</p>
                <p>遇到该问题别担心，复制以下链接至电脑浏览器https://account.edrawsoft.cn/mergeData.html</p>
                <p>打开后，使用微信重新扫码登录即可恢复正常啦！</p>
                <p>若依旧无法恢复，可微信后台留言求助亿图客服。</p>
              </ul>
            </div>
            `,
        },
        {
          index: 5,
          titel: "公告1",
          icon: "&#xe62d;",
          content:
             `
            <div class="content-text">
              <ul>
                <p>极少用户使用微信登录MindMaster软件后，发现云文件丢失，并且个人ID发生改变。</p>
                <p>遇到该问题别担心，复制以下链接至电脑浏览器https://account.edrawsoft.cn/mergeData.html</p>
                <p>打开后，使用微信重新扫码登录即可恢复正常啦！</p>
                <p>若依旧无法恢复，可微信后台留言求助亿图客服。</p>
              </ul>
            </div>
            `,
        },
        {
          index: 6,
          titel: "公告1",
          icon: "&#xe62d;",
          content:
            `
            <div class="content-text">
              <ul>
                <p>极少用户使用微信登录MindMaster软件后，发现云文件丢失，并且个人ID发生改变。</p>
                <p>遇到该问题别担心，复制以下链接至电脑浏览器https://account.edrawsoft.cn/mergeData.html</p>
                <p>打开后，使用微信重新扫码登录即可恢复正常啦！</p>
                <p>若依旧无法恢复，可微信后台留言求助亿图客服。</p>
              </ul>
            </div>
            `
        },
      ],
    };
  },
  created() {},
  mounted() {},
  methods: {
    
    //控制显示
    // ShowHelpContent(index, e) {
    //   this.indexs == index ? (this.indexs = -1) : (this.indexs = index);
    // },
  },
  watch: {},
  computed: {},
};
</script>

<style scoped>
.Help-box {
  margin: 0 auto;
}
ul li {
  list-style: none;
}
.Help-content ul li {
  background-color: #eee;
  padding: 14px;
  color: #666;
  font-size: 16px;
  border-left: 3px solid #999;
  transition: 0.3s;
}
.Help-content ul li:hover {
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
}
.Help-content ul li span {
  float: right;
  transition: 1s;
}
.content{
  margin-top: 14px;
  transition: 1s;
}
.content-text ul p{
  margin: 0 auto !important;
  height: 30px;
}
</style>